import React, { Fragment } from "react";
const Footer = ({ fixture, fixed }) => {
  return (
    <Fragment>
      <div className={`footer--container ${fixed ? "fixed" : ""}`}>
        <p>
          {fixture.text}
          <span className="mdi mdi-copyright"></span>
          {fixture.year}
        </p>
      </div>
      <style jsx>
        {`
          .footer--container {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 20px;
            color: #fafafa;
            background: linear-gradient(to bottom, #2b3845, #060709);
          }
          .footer--container.fixed {
            position: fixed;
            bottom: 0;
            width: 100%;
          }
          .footer--container span {
            margin: 0 5px;
            color: #dfb144;
          }
          .footer--container p {
            display: flex;
            margin: 0;
          }
          @media screen and (max-width: 576px) {
            .footer--container p {
              font-size: 12px;
            }
            .footer--container {
              padding: 20px 10px;
            }
          }
        `}
      </style>
    </Fragment>
  );
};
export default Footer;
