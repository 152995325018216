import React, { Fragment, useEffect, useState } from "react";

const ButtonTop = () => {
  const [scroll, setScroll] = useState(0);
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      setScroll(window.scrollY);
      if (window.scrollY > 200) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [scroll]);
  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <Fragment>
      <div className="button--top" onClick={() => handleClick()}>
        <span className="mdi mdi-arrow-up"></span>
      </div>
      <style jsx>
        {`
          .button--top {
            background-color: rgba(0, 84, 167, 0.6);
            transform: rotate(45deg);
            width: 52px;
            position: fixed;
            bottom: 40px;
            right: 20px;
            font-size: 35px;
            text-align: center;
            color: white;
            opacity: ${visible ? "1" : "0"};
            z-index: ${visible ? "3" : "-10"};
            transition: all 0.5s ease;
            cursor: pointer;
          }
          .button--top span:before {
            transform: translate(0px, -3px) rotate(-45deg);
          }
          .button--top::after,
          .button--top::before {
            content: "";
            display: block;
            position: absolute;
            width: 20%;
            height: 20%;
            border: 2px solid;
            transition: all 0.6s ease;
            border-radius: 2px;
          }
          .button--top:before {
            top: 0;
            margin: -7px;
            left: 0;
            border-bottom-color: transparent;
            border-right-color: transparent;
            border-top-color: #295179;
            border-left-color: #295179;
          }
          .button--top:after {
            bottom: 0;
            right: 0;
            border-top-color: transparent;
            border-left-color: transparent;
            border-bottom-color: #295179;
            border-right-color: #295179;
            margin: -7px;
          }
        `}
      </style>
    </Fragment>
  );
};
export default ButtonTop;
