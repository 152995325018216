export const logo = require("./logo.png");
export const logo_white = require("./logo_white.png");
export const banner_video = require("./home_banner.mp4");
export const engineering = require("./engineering.png");
export const home_contact = require("./home_contact.jpg");
export const calidad = require("./calidad.png");
export const capital = require("./capital.png");
export const autogestion = require("./autogestion.png");
export const nivel = require("./nivel.png");
export const positivismo = require("./positivismo.png");
export const proactividad = require("./proactividad.png");
export const responsabilidad = require("./responsabilidad.png");
export const veraz = require("./veraz.png");
export const compromiso = require("./compromiso.png");
export const diligencia = require("./diligencia.png");
export const honestidad = require("./honestidad.png");
export const innovacion = require("./innovacion.png");
export const english = require("./english_flag.png");
export const spanish = require("./spanish_flag.png");
export const background_contact = require("./background_contact.jpg");
export const whyus_respaldo = require("./whyus_respaldo.jpg");
export const whyus_parallax = require("./whyus_parallax.jpg");
export const allies_1 = require("./allies_1.jpg");
export const allies_2 = require("./allies_2.jpg");
export const allies_3 = require("./allies_3.jpg");
export const allies_4 = require("./allies_4.png");
export const allies_5 = require("./allies_5.jpg");
export const allies_6 = require("./allies_6.jpg");
export const allies_7 = require("./allies_7.jpg");
export const allies_8 = require("./allies_8.jpg");
export const sector_mineria = require("./sector_mineria.jpg");
export const sector_petroleo = require("./sector_petroleo.jpg");
export const sector_automotriz = require("./sector_automotriz.jpg");
export const sector_control = require("./sector_control.jpg");
export const sector_industrial = require("./sector_industrial.jpg");
export const sector_otros = require("./sector_otros.jpg");
export const home_video_thumb = require("./home_video_thumb.jpg");
export const company = require("./company.jpg");
export const personal = require("./personal.jpg");
export const no_match = require("./404.jpg");
export const values_images = {
  item_0: compromiso,
  item_1: responsabilidad,
  item_2: autogestion,
  item_3: nivel,
  item_4: diligencia,
  item_5: calidad,
  item_6: honestidad,
  item_7: proactividad,
  item_8: innovacion,
  item_9: positivismo,
  item_10: veraz,
  item_11: capital,
};

export const allies_images = {
  item_1: allies_1,
  item_2: allies_2,
  item_3: allies_3,
  item_4: allies_4,
  item_5: allies_5,
  item_6: allies_6,
  item_7: allies_7,
  item_8: allies_8,
};

export const sectors = {
  item_1: sector_petroleo,
  item_2: sector_mineria,
  item_3: sector_control,
  item_4: sector_automotriz,
  item_5: sector_industrial,
  item_6: sector_otros,
};
