import React, { Fragment } from "react";
const Loader = ({ text }) => {
  return (
    <Fragment>
      <div className="loader--container grey">
        <div id="global">
          <div className="mask" id="top">
            <div className="plane"></div>
          </div>
          <div className="mask" id="middle-top">
            <div className="plane"></div>
          </div>
          <div className="mask" id="middle-down">
            <div className="plane"></div>
          </div>
          <div className="mask" id="bottom">
            <div className="plane"></div>
          </div>
        </div>
        {text && (
          <div className="loader-text">
            <strong>{text}</strong>
          </div>
        )}
      </div>
      <style jsx>{`
        .loader--container {
          align-items: center;
          display: flex;
          height: 100vh;
          justify-content: center;
          z-index: 22;
          position: fixed;
          top: 0;
          left: 0;
          width: 100vw;
        }
        .loader-text {
          margin-top: 90px;
        }
        #global {
          width: 70px;
          position: absolute;
          height: 75px;
          top: 50%;
          left: 50%;
          margin-top: -37.5px;
          margin-left: -35px;
          transform: rotateY(180deg) rotate(80deg);
        }
        #global .mask {
          position: absolute;
          border-radius: 2px;
          overflow: hidden;
          perspective: 1000;
          backface-visibility: hidden;
        }
        #global .plane {
          background: #0054a7;
          width: 400%;
          height: 100%;
          position: absolute;
          transform: translate3d(0px, 0, 0);
          z-index: 100;
          perspective: 1000;
          backface-visibility: hidden;
        }
        #global #top {
          width: 53px;
          height: 20px;
          left: 12px;
          transform: skew(-15deg, 0);
          z-index: 100;
        }
        #global #top .plane {
          z-index: 2000;
          animation: strip-top 1.3s ease-in infinite 0s backwards;
        }
        #global #middle-top {
          width: 33px;
          height: 20px;
          top: 15px;
          left: 12px;
          transform: skew(-15deg, 40deg);
        }
        #global #middle-top .plane {
          transform: translate3d(0px, 0, 0);
          background: rgba(0, 84, 167, 0.8);
          animation: strip-middle-top 1.3s linear infinite 0.3s backwards;
        }
        #global #middle-down {
          width: 43px;
          height: 19px;
          top: 43px;
          left: 2px;
          transform: skew(-12deg, -32deg);
        }
        #global #middle-down .plane {
          transform: translate3d(0px, 0, 0);
          background: rgba(0, 84, 167, 0.9);
          animation: strip-middle-bottom 1.3s linear infinite 0.7s backwards;
        }
        #global #bottom {
          width: 53px;
          height: 20px;
          top: 55.5px;
          left: 2px;
          transform: skew(-15deg, 0);
        }
        #global #bottom .plane {
          z-index: 2000;
          animation: strip-bottom 1.3s ease-out infinite 0.95s backwards;
        }

        @keyframes strip-top {
          from {
            transform: translate3d(53px, 0, 0);
          }
          to {
            transform: translate3d(-250px, 0, 0);
          }
        }
        @keyframes strip-middle-top {
          from {
            transform: translate3d(-160px, 0, 0);
          }
          to {
            transform: translate3d(53px, 0, 0);
          }
        }
        @keyframes strip-middle-bottom {
          from {
            transform: translate3d(53px, 0, 0);
          }
          to {
            transform: translate3d(-220px, 0, 0);
          }
        }
        @keyframes strip-bottom {
          from {
            transform: translate3d(-220px, 0, 0);
          }
          to {
            transform: translate3d(53px, 0, 0);
          }
        }
      `}</style>
    </Fragment>
  );
};
export default Loader;
