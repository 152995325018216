import React, {
  lazy,
  Suspense,
  useContext,
  Fragment,
  useEffect,
  useState,
} from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import NavBar from "./components/NavBar";
import { LanguageContext } from "./containers/LanguageContext";
import home from "./fixtures/Home.json";
import "../src/assets/css/base.css";
import "../src/assets/css/aos.css";
import contact from "./fixtures/Contact.json";
import why_us from "./fixtures/WhyUs.json";
import sectors from "./fixtures/Sectors.json";
import sectors_mobile from "./fixtures/SectorsMobile.json";
import header_footer from "./fixtures/HeaderFooter.json";
import Loader from "../src/components/Loader";
import ButtonTop from "../src/components/ButtonTop";
import Footer from "../src/components/Footer";
import AOS from "aos";
const Home = lazy(() => import("./containers/Home"));
const WhyUs = lazy(() => import("./containers/WhyUs"));
const Contact = lazy(() => import("./containers/Contact"));
const Sectors = lazy(() => import("./containers/Sectors"));
const NoMatchPage = lazy(() => import("./containers/NoMatchPage"));

function App() {
  const { state } = useContext(LanguageContext);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const dark =
    location.pathname === "/contacto" || location.pathname === "/contact"
      ? true
      : false;
  const stickyMobile =
    location.pathname === "/sectores" || location.pathname === "/sectors"
      ? false
      : true;
  const fixed_footer = false;
  const topButton =
    location.pathname === "/sectores" || location.pathname === "/sectors"
      ? true
      : false;

  useEffect(() => {
    setLoading(false);
    AOS.init({
      duration: 2000,
    });
  }, []);
  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <div className="App">
          <NavBar
            dark={dark}
            stickyMobile={stickyMobile}
            fixture={
              state.lang === "ES"
                ? header_footer.ES.header
                : header_footer.EN.header
            }
          />
          <div className="app--main__content">
            <Suspense fallback={<Loader />}>
              <Switch>
                <Route exact path={"/"}>
                  <Home
                    link={
                      state.lang === "ES"
                        ? header_footer.ES.header
                        : header_footer.EN.header
                    }
                    fixture={state.lang === "ES" ? home.ES : home.EN}
                  />
                </Route>
                <Route path={"/(servicios|our-services)/"}>
                  <Sectors
                    fixture={state.lang === "ES" ? sectors.ES : sectors.EN}
                    fixture_mobile={
                      state.lang === "ES"
                        ? sectors_mobile.ES
                        : sectors_mobile.EN
                    }
                  />
                </Route>

                <Route path={"/(elegirnos|why-us)/"}>
                  <WhyUs
                    fixture={state.lang === "ES" ? why_us.ES : why_us.EN}
                  />
                </Route>
                <Route path={"/(contacto|contact)/"}>
                  <Contact
                    fixture={state.lang === "ES" ? contact.ES : contact.EN}
                  />
                </Route>
                <Route component={NoMatchPage} />
              </Switch>
              {topButton && <ButtonTop />}
            </Suspense>
          </div>
          <Footer
            fixed={fixed_footer}
            fixture={
              state.lang === "ES"
                ? header_footer.ES.footer
                : header_footer.EN.footer
            }
          />
        </div>
      )}
      <style jsx global>
        {`
          .notFixed .navbar--fixed {
            position: relative;
          }
          .notFixed .app--main__content > div {
            padding-top: 0;
          }
          .notFixed .app--main__content {
            height: 100%;
            min-height: calc(100vh - 145px);
          }
        `}
      </style>
    </Fragment>
  );
}

export default App;
