import React, { useState, useEffect, Fragment, useContext } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { logo, logo_white, english, spanish } from "../assets/images/index";
import { LanguageContext } from "../containers/LanguageContext";
import Loader from "../components/Loader";

const NavBar = ({ fixture, dark, stickyMobile }) => {
  const [menu, handleMenu] = useState("close");
  const [scroll, setScroll] = useState(0);
  const [loading, setLoading] = useState(false);
  const { state, dispatch } = useContext(LanguageContext);
  const menuClasses =
    menu === "open"
      ? "navbar--container menu--open"
      : "navbar--container menu--close";
  const location = useHistory();
  useEffect(() => {
    //analytics to location
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scroll]);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  const handleMenuOpen = (closeMenu) => {
    closeMenu && handleMenu("close");
  };
  const callLoader = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      location.push("/");
    }, 2000);
  };
  const navbarClasses =
    scroll >= 250 ? "navbar--fixed shadow " : "navbar--fixed ";
  return (
    <Fragment>
      {loading ? (
        <Loader text={state.lang === "ES" ? "Traduciendo" : "Translating"} />
      ) : (
        <div className={`${navbarClasses} ${dark ? "dark" : ""}`}>
          <nav className={menuClasses}>
            <div className="logo--container" onClick={() => location.push("/")}>
              <img
                title="menu logo"
                src={dark ? logo_white : logo}
                alt="MechproLTD logo"
                loading="lazy"
              />
            </div>

            <div className="menu--container">
              <ul className="menu--content">
                {fixture.options.map((item, i) => {
                  return (
                    <li key={"navOption_" + i}>
                      <NavLink
                        exact={i === 0 ? true : false}
                        to={item.link}
                        onClick={() => handleMenuOpen(true)}
                      >
                        {item.name}
                      </NavLink>
                    </li>
                  );
                })}
              </ul>
            </div>

            <div className="icon--language" onClick={() => callLoader()}>
              <div
                className="select--language"
                onClick={(e) => e.stopPropagation()}
              >
                <img
                  src={state.lang === "ES" ? spanish : english}
                  alt={`language ${state.lang}`}
                  loading="lazy"
                  title="language primary flag"
                />
              </div>
              <div
                className="flip--vertical"
                onClick={() => {
                  state.lang === "ES"
                    ? dispatch({ type: "EN" })
                    : dispatch({ type: "ES" });
                }}
              >
                <img
                  src={state.lang === "ES" ? english : spanish}
                  alt={state.lang === "ES" ? english : spanish}
                  loading="lazy"
                  title="language flag"
                />
              </div>
            </div>
            <button
              className="menu--toggle mobile"
              onClick={() =>
                menu === "close" ? handleMenu("open") : handleMenu("close")
              }
            ></button>
          </nav>
        </div>
      )}

      <style jsx>
        {`
          .navbar--fixed {
            position: fixed;
            z-index: 21;
            width: 100vw;
            background-color: transparent;
            transition: background-color 0.5s ease;
          }
          .navbar--fixed.dark .navbar--container {
            background: rgba(0, 0, 0, 0.8);
            color: #fafafa;
          }
          .navbar--fixed.dark .menu--container .menu--content li :global(a) {
            color: #fafafa;
          }
          .navbar--fixed.dark
            .menu--container
            .menu--content
            li
            :global(a.active) {
            color: #0a85ff;
          }
          .navbar--fixed.dark
            .menu--container
            .menu--content
            li
            :global(a:before),
          .navbar--fixed.dark
            .menu--container
            .menu--content
            li
            :global(a:after) {
            background-color: #0a85ff;
          }
          .navbar--fixed.dark .menu--toggle:before,
          .navbar--fixed.dark .menu--toggle:after {
            background: #fafafa;
          }
          .navbar--container {
            display: flex;
            padding: 0 20px;
            height: 80px;
            padding-right: 35px;
            background: rgba(255, 255, 255, 0.8);
          }
          .logo--container {
            display: flex;
            flex: 1 1 25%;
          }
          .logo--container img {
            cursor: pointer;
            width: 200px;
            height: auto;
            object-fit: contain;
          }
          .menu--toggle {
            flex-grow: 1;
            background-color: transparent;
            border: none;
            z-index: 4;
            cursor: pointer;
            display: none;
          }
          .menu--toggle:before,
          .menu--toggle:after {
            display: block;
            content: "";
            background: #212121;
            width: 40px;
            height: 1px;
          }
          .menu--toggle:before {
            margin-bottom: 6px;
            transition: transform 0.3s ease;
          }
          .menu--toggle:after {
            margin-top: 6px;
            transition: transform 0.3s ease;
          }
          .menu--toggle:hover:before {
            transform: translateY(-2px);
            transition: all 0.3s ease;
          }
          .menu--toggle:hover:after {
            transform: translateY(2px);
            transition: all 0.3s ease;
          }
          .menu--toggle:active,
          .menu--toggle:focus {
            outline: none;
          }
          .navbar--container.menu--open .menu--toggle:before {
            margin: 0;
            transform: translateY(-3px) rotate(45deg);
          }
          .navbar--container.menu--open .menu--toggle:after {
            margin: 0;
            transform: translateY(-5px) rotate(-45deg);
          }
          .navbar--container.menu--open .menu--container {
            opacity: 1;
            visibility: visible;
            transition: opacity 0.5s ease;
          }
          .navbar--container.menu--open .menu--toggle:before,
          .navbar--container.menu--open .menu--toggle:after {
            background-color: #bdbdbd;
            height: 2px;
          }
          .menu--container {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;
            flex: 1 1 75%;
          }
          .menu--content {
            display: flex;
            align-items: center;
            padding: 0;
            margin: 0;
          }
          .menu--content li {
            list-style: none;
            text-align: center;
            cursor: pointer;
            padding: 0 10px;
          }
          .menu--content li :global(a) {
            text-decoration: none;
            color: #212121;
            transition: color 0.3s;
          }
          .menu--content li :global(a:before) {
            top: 0;
            transform: translateY(-12px);
          }
          .menu--content li :global(a:after),
          .menu--content li :global(a:before) {
            position: relative;
            display: flex;
            left: 0;
            width: 100%;
            height: 2px;
            background: #0054a7;
            content: "";
            opacity: 0;
            transition: opacity 0.3s, transform 0.3s;
          }
          .menu--content li :global(a:hover:before),
          .menu--content li :global(a.active:before) {
            opacity: 1;
            transform: translateY(0px);
          }
          .menu--content li :global(a:hover:after),
          .menu--content li :global(a.active:after) {
            opacity: 1;
            transform: translateY(2px);
          }
          .menu--content li :global(a:after) {
            bottom: 0;
            transform: translateY(10px);
          }
          .menu--content li :global(a:hover),
          .menu--content li :global(a.active) {
            color: #0054a7;
          }

          .navbar--fixed.shadow {
            background-color: #ffffffe0;
            transition: all 0.5s ease;
            box-shadow: -1px -2px 17px black;
          }
          .navbar--fixed.dark.shadow {
            background-color: ##000000e0;
          }
          .icon--language .select--language {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 0;
            transform: rotate(45deg);
            width: 25px;
            height: 25px;
            margin: 10px;
            background-color: #ffffffad;
            border: 1px solid transparent;
            opacity: 0.8;
            font-size: 12px;
            font-weight: bold;
            cursor: pointer;
            overflow: hidden;
            border: 2px solid #dba419;
          }
          .icon--language {
            display: flex;
            flex-direction: column;
            justify-content: center;
            cursor: pointer;
          }
          .icon--language div.flip--vertical {
            position: absolute;
            transform: translateY(45px) rotate(45deg);
            transition: all 0.5s ease;
            width: 25px;
            height: 0;
            overflow: hidden;
            margin: 10px;
            opacity: 0;
            border: 2px solid #dba419;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .icon--language:hover div.flip--vertical {
            opacity: 1;
            height: 25px;
            transform-style: preserve-3d;
            transition: all 0.5s ease;
          }

          .select--language img,
          .flip--vertical img {
            width: 40px;
            object-fit: cover;
            height: 40px;
            object-position: left;
            transform: rotate(-45deg);
          }
          @media screen and (max-width: 768px) {
            .menu--toggle {
              display: block;
              flex-grow: 0;
            }
            .navbar--fixed.dark .menu--container {
              background: #212121;
            }
            .navbar--fixed {
              position: ${stickyMobile ? "fixed" : "relative"};
              width: ${stickyMobile ? "100vw" : "100%"};
            }
            .menu--container {
              width: 100%;
              overflow: hidden;
              height: 100vh;
              background: white;
              position: fixed;
              top: 0;
              left: 0;
            }
            .navbar--container.menu--close .menu--container {
              visibility: hidden;
              opacity: 0;
              transition: opacity 0.5s ease;
            }
            .menu--content {
              flex-direction: column;
              width: 100%;
              justify-content: flex-start;
              line-height: 70px;
              font-size: 30px;
            }
          }
        `}
      </style>
    </Fragment>
  );
};
export default NavBar;
